<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="90px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="采购计划单" :class="$i18n.locale">
              <el-input
                v-model="form.purchasePlanCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号" :class="$i18n.locale">
              <el-input
                v-model="form.erpRelationCode"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <Select
                v-model="form.purchaseStatus"
                :select-options="_getAllCommodityDict('PURCHASE_PLAN_STATUS')"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建人" :class="$i18n.locale">
              <el-input
                v-model="form.createByName"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备货渠道">
              <el-select
                v-model="form.stockChannelIdList"
                multiple
                clearable
                filterable
              >
                <el-option
                  v-for="item in StockChannelOptions"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="平台"
              prop="platformName"
              :class="$i18n.locale"
            >
              <el-select
                v-model="form.platformName"
                multiple
                value-key="id"
                clearable
                filterable
              >
                <el-option
                  v-for="item in alldatas.platformList"
                  :key="item.id"
                  :label="item.platformName"
                  :value="item.platformName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点" prop="siteCode" :class="$i18n.locale">
              <el-select
                v-model="form.siteCode"
                multiple
                value-key="id"
                clearable
                filterable
              >
                <el-option
                  v-for="item in alldatas.siteList"
                  :key="item.id"
                  :label="item.siteCode"
                  :value="item.siteCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Style" :class="$i18n.locale">
              <el-select
                v-model="form.style"
                oninput="(/\s+/g, '')"
                multiple
                value-key="id"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in styleOptions"
                  :key="index"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU" :class="$i18n.locale">
              <el-select
                v-model="form.operatorGroup"
                multiple
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in groupoptions"
                  :key="index"
                  :label="item.groupName"
                  :value="item.groupName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Operator" :class="$i18n.locale">
              <el-select
                v-model="form.operatorUser"
                multiple
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in useroptions"
                  :key="index"
                  :label="item.userName"
                  :value="item.userName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="单据类型">
              <Select
                v-model="form.sourceTypeDictList"
                :select-options="_getAllCommodityDict('PR_SOURCE_TYPE')"
                clearable
                multiple
              />

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划类型" :class="$i18n.locale">
              <Select
                v-model="form.type"
                :select-options="_getAllCommodityDict('PURCHASE_PLAN_TYPE')"
                clearable
              />

            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="form.startDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="开始时间"
                type="datetime"
              />
              ~
              <el-date-picker
                v-model="form.endDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="结束时间"
                type="datetime"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <router-link to="addedplanorder" append>
              <el-button class="mr-2" type="primary">新增</el-button>
            </router-link>
            <el-button
              :loading="exportLoading"
              @click="handleExport('data')"
            >导出</el-button>
            <el-button
              :loading="exportLoading"
              @click="handleExport('detail')"
            >导出明细</el-button>
            <el-button
              v-permission="'Shortage'"
              type="primary"
              @click="handleShortage()"
            >缺量完成</el-button>
            <el-button
              type="primary"
              class="mr-2"
              @click="generateorders()"
            >生成采购订单</el-button>
            <ImportFile
              ref="importfile"
              v-model="detailDialog"
              v-permission="'import'"
              :upload-file="uploadFile"
              :import-details-columns="importDetailsColumns"
              :auto-upload="true"
              style="display: inline-block !important"
              :details-data="importDatas"
              :import-submit-loading="importSubmitLoading"
              :show-export="true"
              :detail-error-tip="true"
              @getFile="({ file: val }) => (file = val)"
              @getSelectionChange="(val) => (selectImportData = val)"
              @submitDetailDatas="submitDetailDatas"
            />
            <el-button
              v-permission="'import'"
              type="text"
              class="ml-2"
              :loading="templateLoading"
              @click="downTemplate('PURCHASE_PLAN_TEMPLATE')"
            >导入模板下载</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">
            {{ $t("page.search") }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      sort-change="sortChange"
      :data="tableDatas"
      max-height="600px"
      show-summary
      :summary-method="getSummaries"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column
        :label="$t('page.No')"
        type="index"
        width="80"
        align="center"
        sortable
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="purchasePlanCode"
        label="采购计划单号"
        sortable
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <router-link
            :to="{
              path: '/purchase-plan-management/purchasplan/vieworder',
              query: { id: scope.row.id, disabled: true },
            }"
            style="color: #1890ff"
          >{{ scope.row.purchasePlanCode }}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="erpRelationCode"
        label="关联单号"
        sortable
        width="110"
        align="center"
      />
      <el-table-column
        prop="expectDeliveryDate"
        label="期望交货日期"
        sortable
        width="140"
        align="center"
      />
      <el-table-column
        prop="purchaseStatusI18"
        label="状态"
        sortable
        width="110"
        align="center"
      />

      <el-table-column label="单据类型" sortable width="110" align="center" prop="sourceTypeI18" />
      <el-table-column label="计划类型" sortable width="110" align="center" prop="typeI18" />
      <el-table-column
        prop="stockChannelName"
        label="备货渠道"
        width="140"
        sortable
        align="center"
      />

      <el-table-column
        prop="platformName"
        label="平台"
        width="100"
        sortable
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="站点"
        width="100"
        sortable
        align="center"
      />
      <el-table-column
        show-overflow-tooltip
        prop="style"
        label="Style"
        width="100"
        sortable
        align="center"
      />

      <el-table-column
        prop="totalPairs"
        sortable
        label="数量"
        width="90"
        align="center"
      />
      <el-table-column
        prop="turnPairs"
        label="已转订单数量"
        sortable
        width="140"
        align="center"
      />

      <el-table-column
        prop="unTurnPairs"
        label="未转订单数量"
        sortable
        width="140"
        align="center"
      />

      <el-table-column
        prop="totalInventoryPairs"
        label="库存总数量"
        sortable
        width="140"
        align="center"
      />
      <el-table-column
        prop="operatorGroup"
        label="BU"
        sortable
        width="140"
        align="center"
      />
      <el-table-column
        prop="operatorUser"
        label="Operator"
        sortable
        width="140"
        align="center"
      />
      <el-table-column
        prop="createByName"
        label="创建人"
        sortable
        width="100"
        align="center"
      />
      <el-table-column
        prop="createTime"
        label="创建时间"
        sortable
        width="180"
        align="center"
      />
      <el-table-column
        fixed="right"
        :label="$t('page.operate')"
        width="230"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="modifypurchaseplan(scope.row, true)"
          >
            <p
              v-if="
                scope.row.purchaseStatus == 0 || scope.row.purchaseStatus == 5
              "
            >
              修改
            </p>
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="handledetail(scope.row.purchasePlanCode, true)"
          >
            <p>查看转单明细</p>
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="handleDelete(scope.row.id)"
          >
            <p
              v-if="
                scope.row.purchaseStatus == 0 || scope.row.purchaseStatus == 5
              "
            >
              作废
            </p>
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="handleRevoke(scope.row.id)"
          >
            <p
              v-if="
                scope.row.purchaseStatus == 1 || scope.row.purchaseStatus == 2
              "
            >
              撤销
            </p>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <el-dialog
      width="80%"
      title="当前Style未生成采购订单PR"
      :visible.sync="dialogTableVisible"
    >
      <span>当前Style仍有其他审批通过的采购计划单未被选择，可补充选择，或按现有已选择采购计划单继续生成采购订单：</span>
      <el-table
        ref="tableRef"
        :data="approveData"
        class="approveTable"
        :height="500"
        @selection-change="handleSelectionApproveChange"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="selectEnable"
        />
        <el-table-column label="序号" type="index" width="50" />
        <el-table-column
          property="purchasePlanCode"
          label="采购计划单号"
          width="150"
        />
        <el-table-column property="purchaseStatus" label="状态">
          <template slot-scope="{ row }">
            <span v-if="row.purchaseStatus == '2'"> 审批通过 </span>
            <span v-if="row.purchaseStatus == '3'"> 部分转订单 </span>
          </template>
        </el-table-column>
        <el-table-column property="style" label="Style" />
        <el-table-column property="stockChannelName" label="备货渠道" />
        <el-table-column property="totalPairs" label="数量" />
        <el-table-column property="expectDeliveryDate" label="期望交货日期" />
        <el-table-column property="createTime" label="创建时间" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitApprove">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  findDownloadUrl,
  purchasePlanImport,
  purchasePlanImportView,
  purchasePlanShortage,
  deleteplanid,
  purchaseplanpage,
  revoke,
  StockChannelList
} from '@/api/scm-api'
import Paging from '@/components/Pagination'
import ImportFile from './components/ImportFile'
import { importDetailsColumns } from '@/constant/tablecolumns'
import { downloads } from '@/utils'
import Select from '@/components/Selection'

import {
  listByPrincipalConfig,
  styleListConfig,
  buListConfig
} from '@/api/configuration'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import {
  purchasePlanToOrderCheck,
  purchasePlanToOrderOther
} from '@/api/supply'
import { exportPurchasePlanDetail, exportPurchasePlan } from '@/api/export'
import { handleBreadTitle, trackExportEvent, trackImportEvent } from '@/utils/monitor'

export default {
  components: { Paging, ImportFile, Select },
  mixins: [commodityInfoDict],
  data() {
    return {
      exportLoading: false,
      code: '',
      templateLoading: false,
      dialogTableVisible: false,
      importSubmitLoading: false,
      selectImportData: [],
      approveData: [],
      purchasePlanCodeList: [],
      multipleSelection: [],
      importDetailsColumns,
      sidvoList: [],
      TableLoading: false,
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false,
      tableDatas: [],
      buList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      useroptions: [],
      groupoptions: [],
      form: {
        style: [],
        platformName: [],
        siteCode: [],
        operatorGroup: [],
        operatorUser: [],
        purchasePlanCode: '',
        purchaseStatus: []
        // createByName: ''
      },
      styleOptions: [],
      StockChannelOptions: [],
      alldatas: [],
      purchasePlanCode: '',
      detailExportKeys: 'scm_purchase_plan_detail',
      file: '',
      importDatas: [],
      detailDialog: false
    }
  },
  computed: {
    queryParameter() {
      const { style, platformName, siteCode, operatorGroup, operatorUser, purchaseStatus } =
        this.form
      return Object.assign({}, this.pager, this.form, {
        style: style.join(','),
        platformName: platformName.join(','),
        siteCode: siteCode.join(','),
        operatorGroup: operatorGroup.join(','),
        operatorUser: operatorUser.join(','),
        purchaseStatus: purchaseStatus.join(',')
      })
    },
    queryDetailParameter() {
      const purchasePlanCode = this.sidvoList
        .map((v) => v.purchasePlanCode)
        ?.join()
      return { purchasePlanCode }
    }
  },
  mounted() {
    this.$store.commit('pages/SET_MOQ_COMFIRM_TABLEDATA', [])
    const defaultForm = this.$store.getters.purchasePlanForm || {}
    this.form = Object.assign(
      {},
      this.$options.data.call(this).form,
      defaultForm
    )
    this.queryClick()
    this._listPlatformAndSite()
    this._queryStyleList()
    this._listBuAndOperator()
    this._StockChannelList()
  },
  methods: {
    pagerUpdate(val) {
      this.pager = val
      this._purchaseplanpage()
    },
    async downTemplate(key) {
      try {
        this.templateLoading = true
        const { datas } = await findDownloadUrl(key)
        downloads(datas)
      } finally {
        this.templateLoading = false
      }
    },
    async submitDetailDatas() {
      try {
        this.importSubmitLoading = true
        const { code, msg } = await purchasePlanImport(this.selectImportData)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.detailDialog = false
          this.queryClick()
        }
      } finally {
        this.importSubmitLoading = false
      }
    },
    async uploadFile() {
      try {
        const form = new FormData()
        form.append('file', this.file)
        const { code, msg, datas } = await purchasePlanImportView(form)
        if (code === 0) {
          this.importDatas = datas.map((item, index) => {
            item.expectList.map((e) => {
              item['expectDeliveryDate' + e.no] = e.expectDeliveryDate
              item['expectPairs' + e.no] = e.expectPairs
            })
            let tipMsg = ''
            if (item.warnEol) {
              tipMsg += '存在EOL产品是否翻单;'
            }
            if (item.warnNegative && item.season !== '5') {
              tipMsg += '存在负现金流两季款产品是否翻单'
            }
            const errorTip =
              item.warnNegative && item.season === '5'
                ? '存在负现金流产品不允许翻单'
                : ''
            return {
              ...item,
              index: index + 1,
              tipMsg,
              errorMsg: item.errorMsg || errorTip
            }
          })
          this.detailDialog = true
          this.$notify({
            message: msg,
            type: 'success'
          })
          trackImportEvent(`${handleBreadTitle(this.$route)}-导入`)
        }
      } finally {
        this.$refs.importfile.clearFile()
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index >= 12 && index <= 15) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              prev =
                (prev + '').indexOf('.') === -1
                  ? prev
                  : Number(prev.toFixed(2))
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    formatter(row, column) {
      return row.address
    },
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await styleListConfig()
      this.styleOptions = datas
    },
    // 选择平台带出站点
    async _listPlatformAndSite() {
      const { datas } = await listByPrincipalConfig()
      this.alldatas = datas
    },
    // BUOperator
    async _listBuAndOperator() {
      const { datas } = await buListConfig()
      this.groupoptions = datas
      this.groupoptions.map((e) => {
        e.list.map((i) => {
          this.useroptions.push(i)
        })
      })
    },

    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._purchaseplanpage()
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = this.$options.data().form
      this.queryClick()
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _purchaseplanpage() {
      try {
        this.TableLoading = true
        const { datas } = await purchaseplanpage(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
        this.$store.dispatch('saveForm/savePurchasePlan', this.form)
      }
    },
    // 通过id作废采购计划
    handleDelete(row) {
      this.$confirm('确认要作废采购计划单么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this._deleteplanid(row)
      })
    },
    // 通过id作废采购计划
    async _deleteplanid(row) {
      // msg,
      const { msg } = await deleteplanid(row)
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
      this.queryClick()
    },
    // 通过id撤销采购计划
    handleRevoke(id) {
      this.$confirm('确认要撤销采购计划单么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        const { msg } = await revoke(id)
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.queryClick()
      })
    },
    // 修改、查看
    modifypurchaseplan({ id, style }, disabled) {
      this.$router.push({
        path: 'addedplanorder',
        append: true,
        query: { id, style, disabled: disabled, isQury: true }
      })
    },
    handledetail(row, disabled) {
      this.$router.push({
        path: 'vieworderdetail',
        append: true,
        query: { id: row, disabled: disabled }
      })
    },
    handleSelectionChange(val) {
      this.sidvoList = val
    },
    handleSelectionApproveChange(val) {
      this.multipleSelection = val
    },

    async handleExport(type) {
      if (type === 'detail' && !this.sidvoList.length) {
        return this.$message({
          message: '请至少选择一条计划单',
          type: 'warning'
        })
      }
      try {
        this.exportLoading = true
        type === 'detail'
          ? await exportPurchasePlanDetail(this.queryDetailParameter)
          : await exportPurchasePlan(this.queryParameter)
        this.$notify({
          message: '操作成功，请前往文件导出界面下载',
          type: 'success'
        })
        trackExportEvent(`${handleBreadTitle(this.$route)}-${type === 'data' ? '导出' : '导出明细'}`)
      } finally {
        this.exportLoading = false
      }
    },
    // 缺量完成
    async handleShortage() {
      const { sidvoList } = this
      if (!sidvoList.length) {
        this.$message({
          message: '请至少选择一条采购计划单',
          type: 'warning'
        })
        return false
      }
      if (!sidvoList.every((v) => v.purchaseStatus === 3)) {
        this.$message({
          message: '请选择单据状态=部分转订单可操作!',
          type: 'warning'
        })
        return
      }

      this.$confirm('提示', {
        title: '提示',
        message: `您选中了 ${this.sidvoList.length} 笔采购计划单，该操作将缺量完成采购计划单，是否继续？`,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async() => {
          const params = {}
          Object.assign(params, {
            purchasePlanCodeList: sidvoList.map(
              (item) => item.purchasePlanCode
            )
          })
          const { msg } = await purchasePlanShortage(params)
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.queryClick()
        })
        .catch((action) => {})
    },
    // 生成采购订单
    generateorders() {
      if (this.sidvoList.length === 0) {
        this.showMessage('请至少选择一条审批通过的采购计划单', 'warning')
        return
      }

      const approvedStatuses = ['2', '3']
      if (this.sidvoList.some(v => !approvedStatuses.includes(v.purchaseStatus + ''))) {
        return this.showMessage('请选择部分转订单、审批通过状态的采购订单', 'warning')
      }

      const singleStyle = this.sidvoList.every(v => v.style === this.sidvoList[0].style)
      if (!singleStyle) {
        return this.showMessage('请选择相同Style的采购计划单', 'warning')
      } else {
        this.purchasePlanCodeList = this.sidvoList.map(v => v.purchasePlanCode)
        this._purchasePlanToOrderCheck({ purchasePlanCodeList: this.purchasePlanCodeList })
      }
    },

    showMessage(message, type) {
      this.$message({ message, type })
    },

    async submitApprove() {
      // 关闭弹窗
      this.dialogTableVisible = false
      const { multipleSelection, purchasePlanCodeList } = this
      let data = []
      Array.isArray(multipleSelection) &&
        multipleSelection.map((item) => data.push(item.purchasePlanCode))
      data = [...new Set([...data, ...purchasePlanCodeList])]
      const { style: styleName } = this.sidvoList[0]
      const styleId = this.styleOptions.find(v => v.styleName === styleName)?.id
      // 调用接口，获取返回值
      const { datas } = await purchasePlanToOrderCheck({
        purchasePlanCodeList: data
      })
      // 弹出提示框
      if (Array.isArray(datas) && datas.length) {
        this.$confirm(`${datas.join(';')} 是否继续操作？`, '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({
            path: 'generateorder',
            append: true,
            query: { purchasePlanCodeList: `${data}`, styleId, styleName, fromPath: 'purchasplan' }
          })
        })
      } else {
        this.$router.push({
          path: 'generateorder',
          append: true,
          query: { purchasePlanCodeList: `${data}`, styleId, styleName, fromPath: 'purchasplan' }
        })
      }
    },
    async _purchasePlanToOrderCheck(row) {
      try {
        // 获取当前行的数据
        const { datas } = await purchasePlanToOrderOther(row)
        const { purchasePlanCodeList } = row
        if (
          Array.isArray(datas) &&
          datas.length !== purchasePlanCodeList.length
        ) {
          // 显示表格
          this.dialogTableVisible = true
          // 将当前行的数据赋值给 approveData
          this.approveData = datas
          // 设置当前行的数据
          this.setApproveData(datas, purchasePlanCodeList)
        } else {
          // 提交申请
          this.submitApprove()
        }
      } catch (error) {
        // 捕获错误
      }
    },
    setApproveData(datas, purchasePlanCodeList) {
      this.$nextTick(() => {
        datas.map((v) => {
          if (purchasePlanCodeList.includes(v.purchasePlanCode)) {
            this.$refs.tableRef.toggleRowSelection(v, true)
          }
        })
      })
    },
    // 备货渠道下拉框
    async _StockChannelList() {
      const { datas } = await StockChannelList('')
      this.StockChannelOptions = datas
    },
    // 默认第一条不可勾选的
    selectEnable(row, rowIndex) {
      return !this.purchasePlanCodeList.includes(row.purchasePlanCode)
    }
  }
}
</script>
<style lang="scss" scope>
.el-tooltip__popper {
  max-width: 20%;
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  background: #e6e6e6 !important;
  color: #303133 !important;
}
.approveTable {
  .el-table__header-wrapper {
    .el-checkbox {
      display: none;
    }
  }
}
.break-class {
  word-break: break-all;
}
.el-message-box {
  width: 80%;
}
</style>
